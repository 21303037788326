<template>
    <div id="qr-code-full-region"></div>
</template>

<script>

    export default {
        name: 'qr-code-reader',
        props: {
            qrbox: {
                type: Number,
                default: 250
            },
            fps: {
                type: Number,
                default: 10
            },
        },

        mounted () {
            const config = {
                fps: this.fps,
                qrbox: this.qrbox,
                aspectRatio: 1.777778, // 16:9
                disableFlip: true, // scan for horizontally flipped QR Codes
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.EAN_13
                ],
                experimentalFeatures: {
                    useBarCodeDetectorIfSupported: true
                }

            };
            const verbose = false;
            // eslint-disable-next-line no-undef
            this.html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config, verbose);
            this.html5QrcodeScanner.render(this.onScanSuccess);
        },
        unmounted() {
            this.html5QrcodeScanner.clear();
        },
        methods: {
            onScanSuccess (decodedText, decodedResult) {
                this.$emit('result', decodedText, decodedResult);
            }
        }
    };
</script>
